<div class="access_denied">
  <div>
    <img src="/images/access-denied.png">
    <h1>Access Denied</h1>
    <h3>
      Sorry, your role does not currently have access to this page or resource.
      <br />
      If you would like more detail about roles and permissions,
      <br />
      please contact your Team Leader or IT System Administrator.
      <br />
      Thank you
    </h3>
  </div>
</div>
