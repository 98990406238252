<div class="orderModal">
    <!-- cstmModalBody -->
    <div class="cstmModalBody">
      <!-- cstmModalCont -->
      <div class="cstmModalCont">
        <div class="orderBox">
          <div mat-dialog-title style="text-align: center;"><img src="/images/order-icon1.svg"></div>
          <h5>You have been Idle for a long Time !</h5>
          <p>Time Stamp <span>15/04/2021</span></p>
          <p>User <span>{{patientName}}</span></p>
          <p> Are you sue to logOut?</p>
          <div class="btnsBg">
            <button class="btn common-btn">Log me back in</button>
            <button class="btn cancelBtn" (click)="onNoClick()">Log me out</button>
          </div>
        </div>
      </div>
    </div> 
    </div>
