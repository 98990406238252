 <!-- wrapper -->
 <div class="wrapper col">
    <div class="row LoginBg">

      <div class="col lftImg"></div>
      <div class="col signTextBg">
        <div class="d-flex">
          <a href=""><img src="/images/logo1.svg"></a>
          <div class="signText">
            <div class="textBox">
              Our mission is to 
make lives easier for 
patients and doctors
            </div>
          </div>
          <div class="copyright-inner m-0">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. {{version}} </div>
        </div>
      </div>

      <!-- logIn-Rght -->
      <div class="col logIn-Rght">
        <div class="loginCont updated-Cont">
          <img src="/images/green-check.svg" class="mb-4">
          <h1>Password Updated</h1>
          <p>Your password has been updated!</p>
          <button type="button" (click)="Login()" class="common-btn mt-4">Login</button>
        </div>
      </div>
      <!-- logIn-Rght -->

    </div>
  </div>
  <!-- wrapper -->