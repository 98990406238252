import { MaterialsModule } from './../../app/material.module';

import { ApplicationRoutes } from '../../app/routes/inserviorootrouting';
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { MainNavComponent } from './mainNav.component';


@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      FormsModule,
      MaterialsModule,
      ApplicationRoutes,
    ],
    exports: [MainNavComponent],
    declarations: [MainNavComponent],
    providers: [],
  })
  export class MenuModule { }