import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-idealtimer',
    templateUrl: './idealtimer.component.html',
    //styleUrls: ['./idealtimer.component.sass'],
  })
  export class IdealTimerComponent implements OnInit {
    isChecked = true;
    patientName = '';
    constructor(public dialogRef: MatDialogRef<IdealTimerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any)
    {
      this.patientName = data.name;
    }

    ngOnInit(): void {
        
      }

      onNoClick(): void {
        this.dialogRef.close();
      }


      

    
  }
