import { MenuItemViewModel } from './mainNav.viewmodel';
import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
import { AuthGuard } from 'inservioweb/app/_guards';
@Component({
  selector: 'app-main-nav',
  templateUrl: './mainNav.component.html',
  styleUrls: ['./mainNav.component.scss']
})
export class MainNavComponent implements OnInit {
  opened: Boolean;
  MenuViewModel: MenuItemViewModel[] = [];
  MainMenuViewModel: MenuItemViewModel[];
  subMenuViewModel: MenuItemViewModel[];
  constructor(
    private AuthGuard: AuthGuard
  ) { this.opened = true; }

  ngOnInit(): void {
    $(document).on('click', '.nav_menu li', function () {
      $(this).addClass('active').siblings().removeClass('active');
    });

    this.ExecuteListAsync();

  }
  public ExecuteListAsync() {
    const ToeknData = this.AuthGuard.GetTokenDetail();
    this.MenuViewModel = ToeknData.menuList;
    this.MainMenuViewModel = ToeknData.menuList.filter(function (e: any) { return e.parentId == 0; });
    this.subMenuViewModel = ToeknData.menuList.filter(function (e: any) { return e.parentId != 0; });
    var sortedArray = this.subMenuViewModel.sort((n1: MenuItemViewModel, n2: MenuItemViewModel) => n1.subOrderNo - n2.subOrderNo);

  }
  public hasPermission(discriminator: string) {
    const result = this.AuthGuard.isAuthenticated(discriminator);
    return result;
  }

}
