import { IdealTimerComponent } from './../modules/idealTimer/idealtimer.component';
import { DateRangeHeaderComponent } from './pulgins/date-range-picker/date-range-header.component';
import { CustomDateRangeComponent } from './pulgins/date-range-picker/date-range-picker.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { MatSort } from '@angular/material/sort';
import { RecoverycodesComponent } from './../modules/recoverycodes/recoverycodes.component';
import { TwofactorauthComponent } from './../modules/twofactorauth/twofactorauth.component';
import { MaterialsModule } from './material.module';
import { MenuModule } from './../modules/mainNav/menu.module';
import { ApplicationRoutes } from './routes/inserviorootrouting';
import { PrivateComponent } from './layouts/private/private.component';

import { UpdatepasswordComponent } from './../modules/updatepassword/updatepassword.component';
import { ResetsuccessComponent } from './../modules/resetsuccess/resetsuccess.component';
import { OTPverificationComponent } from './../modules/otpverification/otpverification.component';
import { ForgetpasswordComponent } from './../modules/forgetpassword/forgetpassword.component';
import { DashboardComponent } from '../modules/dashboard/dashboard';
import { LoginComponent } from '../modules/login/login';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InservioAppComponent } from '../app/inservioappcomponent';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageNotFoundComponent } from '../common/pagenotfound/pagenotfound';
import * as InServioPulgins from '../app/pulgins/inserviopulgins';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import * as InservioProvider from '../app/providers/publicprovider';
import { HttpClientModule } from '@angular/common/http';
import { PublicComponent } from './layouts/public/public.component';
import * as $ from "jquery";
import { MatTableModule } from '@angular/material/table';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { AvatarModule } from "ngx-avatars";
import { NgxMaskModule } from 'ngx-mask';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
//import{MomentModule} from 'angular2-moment';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { ToastrModule } from 'ngx-toastr';

export function momentAdapterFactory() {
  return adapterFactory(moment);
};

export const MY_FORMATS = {
  parse: {
    dateInput: ['DD/MM/YYYY'],
  },
  display: {
    dateInput: 'DD/MM/yyyy',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@NgModule({
  declarations: [
    InservioAppComponent,
    LoginComponent,
    PageNotFoundComponent,
    DashboardComponent,
    ForgetpasswordComponent,
    OTPverificationComponent,
    ResetsuccessComponent,
    UpdatepasswordComponent,
    TwofactorauthComponent,
    RecoverycodesComponent,
    PrivateComponent,
    PublicComponent,
    CustomDateRangeComponent,
    DateRangeHeaderComponent,
    IdealTimerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    NgxLocalStorageModule.forRoot(),
    HttpClientModule,
    ApplicationRoutes,
    MenuModule,
    MaterialsModule,
    InServioPulgins.plugins,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatTableExporterModule,
    AvatarModule,
    NgxMaskModule.forRoot(),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    NgIdleKeepaliveModule.forRoot(),
    //MomentModule,
    ToastrModule.forRoot()
  ],
  providers: [InservioProvider.Providers,
  
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  bootstrap: [InservioAppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InservioWebModules { }
