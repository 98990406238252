import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'inservioweb/services/requestservice';
import * as APIMethodConfig from '../../common/apimethod';

@Component({
  selector: 'app-resetsuccess',
  templateUrl: './resetsuccess.component.html',
  styleUrls: ['./resetsuccess.component.sass']
})
export class ResetsuccessComponent implements OnInit {
  currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
  constructor(public router: Router,public requestService:RequestService) { }

  ngOnInit(): void {
    this.GetInservioversionList();

  }
  public Login() {
    this.router.navigate(['/login']);
  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {
  
        this.VersionList = response.entity;
        if(this.VersionList.length>0)
        {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();          
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }
}
