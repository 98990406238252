import { LocalStorageService } from 'ngx-localstorage';
import { Router } from '@angular/router';


import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import * as ConfigKey from '../common';


@Injectable({
    providedIn:'root'
})
export class InterceptorService implements HttpInterceptor {

    constructor(private router: Router, private storageService: LocalStorageService){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    

   // return next.handle(httpRequest);
    return next.handle(request).pipe(
        tap(event =>{
            if(event instanceof HttpResponse){
            
                const token= event.body.token;
                if (token !== '' && token !== null && token !== undefined) {
                   // localStorage.setItem('token', token);
                  // let tokenDetailencoded = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(token), ConfigKey.Common.ENCRYPT.SECRETKEY).toString());
                  // this.storageService.set(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY, tokenDetailencoded);
                }
            }
        },error=>{
            if (error.status === 401) {
                console.log('unauthorized');
                this.router.navigate(['./statuscode']);
            }
        })
        );
    

  }
}