import { DateRangeHeaderComponent } from './../../app/pulgins/date-range-picker/date-range-header.component';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderListResponseViewmodel } from './../order/viewmodels/orderlist.response';
import { RequestService } from './../../services/requestservice';
import { AuthGuard } from './../../app/_guards/auth.guard';
import { LocalStorageService } from 'ngx-localstorage';
import { NavigationStart, Router } from '@angular/router';
import { Component, HostListener, OnInit } from '@angular/core';
import * as ConfigKey from '../../common/common';
import * as APIMethodConfig from '../../common/apimethod';
import { DatePipe, PlatformLocation } from '@angular/common';
import * as moment from 'moment';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { fromEvent, Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'inservio-dashboard',
  templateUrl: './dashboard.html',
  styleUrls: ['./dashboard.scss'],
  providers: [DatePipe]

})

export class DashboardComponent implements OnInit {
  Loader: boolean = true;
  fullName: string = '';
  OrderListView: any;
  TaskListView: any;

  pageEvent: PageEvent;
  currentPage = 0;
  pageSize = 10;
  resultsLength = 0;
  totalrows = 0;

  Datechstart = '';
  Dateend = '';
  public IsOrderListPermission: boolean = false;
  public IsTaskListPermission: boolean = false;
  public IsTaskAddPermission: boolean = false;
  public IsTaskEditPermission: boolean = false;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl(),
  });
  private unsubscriber: Subject<void> = new Subject<void>();
  readonly DateRangeHeaderComponent = DateRangeHeaderComponent;

  constructor(public router: Router,
    private storageService: LocalStorageService,
    public requestService: RequestService,
    private AuthGuard: AuthGuard,
    public datepipe: DatePipe,
    private titleService: Title,
    location: PlatformLocation
  ) {
    

  }

  ngOnInit() {
    // this.hasPermission();
    this.Loader = false;
    this.titleService.setTitle('Welcome Home');
    var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
    const ToeknData = this.AuthGuard.GetTokenDetail();
    if (ToeknData != null) {
      this.fullName = ToeknData.fullName;
    }
    //this.ExecuteOrderListing();
  //   this.executeListing();
  //   this.ExecuteTaskListing();

  //   this.range.valueChanges.pipe(debounceTime(1000))
  //     .subscribe(
  //       p => {
  //         this.Datechstart = moment(p.start).format('MM/DD/yyyy');
  //         this.Dateend = moment(p.end).format('MM/DD/yyyy');
  //         if (this.Datechstart == 'Invalid date') {
  //           this.Datechstart = '';
  //         }
  //         if (this.Dateend == 'Invalid date') {
  //           this.Dateend = '';
  //         }
  //         this.currentPage = 0;
  //         this.totalrows = 0;
  //         this.resultsLength = this.totalrows;
  //         this.executeListing();
  //         this.ExecuteTaskListing();
  //       }
  //   );

  //   var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
  //   if (Key != null) {
  //     history.pushState(null, '');
  //     fromEvent(window, 'popstate').pipe(
  //       takeUntil(this.unsubscriber)
  //     ).subscribe((_) => {
  //       history.pushState(null, '');
  //       //alert(`You can't make changes or go back at this time.`);
  //     });
  //   }
  // }

  // public ExecuteOrderListing() {
  //   const fipso = {
  //     'therapydlDatestart': this.Datechstart,
  //     'therapydlDateend': this.Dateend
  //   };
  //   this.requestService.HttpGet<OrderListResponseViewmodel>(APIMethodConfig.InservioAPIMethod.Orders.Order, fipso).
  //     subscribe((response: OrderListResponseViewmodel) => {
  //       this.OrderListView = response.entity;
  //     }, (response: any) => this.onFailed(response));
  // }
  // public ExecuteTaskListing() {

  //   var data = {
  //     Limit: 20,
  //     'StartDate': this.Datechstart,
  //     'EndDate': this.Dateend
  //   };
  //   this.requestService.HttpGet<any>(APIMethodConfig.InservioAPIMethod.Task.TaskList, data).
  //     subscribe((response: any) => {
  //       if (response.status) {
  //         this.TaskListView = [];
  //         this.TaskListView = response.entity;
  //         this.TaskListView.forEach(x => {
  //           if(x.taskStatus == 'Todo'){
  //             x.taskStatus = 'To Do'
  //           }
  //         });
  //       }
  //     }, (error: any) => console.log(error));
  // }

  // public onFailed(error: HttpErrorResponse) {
  //   const errorResponse: OrderListResponseViewmodel = error.error;
  //   if (error.status > 0) {
  //     // this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
  //   } else {
  //     //this.alertService.ShowErrorMessage(error.message);
  //   }
  // }

  // clearDate(event) {
  //   this.range.reset();
  //   this.executeListing();
  //   event.stopPropagation();
  // }

  // public SetValue(item: string) {
  //   if (item == '' || item == null) {
  //     return '-';
  //   }
  //   else {
  //     return item;
  //   }
  // }

  // public hasPermission() {
  //   this.IsOrderListPermission = this.AuthGuard.isAuthenticated('Order.List');
  //   this.IsTaskListPermission = this.AuthGuard.isAuthenticated('Task.List');
  //   this.IsTaskAddPermission = this.AuthGuard.isAuthenticated('Task.Add');
  //   this.IsTaskEditPermission = this.AuthGuard.isAuthenticated('Task.Edit');
  // }



  // public executeListing() {
  //  // this.tableLoader = true;
  //   const fipso = {
      
  //     'therapydlDatestart': this.Datechstart,
  //     'therapydlDateend': this.Dateend,      
  //     'CurrentPage': this.currentPage,
  //     'PageSize': this.pageSize

  //   };
  //   //this.orderListViewModel.dataArray = new Array();
  //   //this.orderListViewModel.totalData = Number(this.showItems);

  //   this.requestService.HttpGet<OrderListResponseViewmodel>(APIMethodConfig.InservioAPIMethod.Orders.Order + "/GetOrdersListDashboard", fipso).
  //     subscribe((response) => {
  //       this.OrderListView = response.entity;
  //       this.resultsLength = response.totalRows;
  //       //this.tableLoader = false;
  //     }, (response: any) => this.onFailed(response));
  //   //this.paginationCommon.setPagination(this.configPagination);
  // }

  // getServerData(event?: PageEvent) {
  //   console.log(event);
  //   this.resultsLength = this.totalrows;
  //   this.currentPage = event.pageIndex + 1;
  //   this.pageSize = event.pageSize;
  //   //this._pagesize = event.pageSize;
  //   //this.limit = this.limit + this.defaultLimit;
  //   /*this.ispagination = true;*/
  //   this.executeListing();
  //   return event;
  // }
  // ngOnDestroy(): void {
  //   this.unsubscriber.next();
  //   this.unsubscriber.complete();
  // }
  // //@HostListener('window:popstate', ['$event'])
  // //onPopState(event) {
  // //  var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
  // //  if (Key != null) {
  // //    //this.router.navigate(['/dashboard']);
  // //    window.location.href ="/dashboard";
  // //  }
  // //}
}
}





