<router-outlet></router-outlet>
<button type="button" id="openModalButton" class="btn btn-info btn-lg" [hidden]="true" (click)="openModal()">click to open</button>

<div class="modal cstmModal orderModal" tabindex="-1" role="dialog" id="idealModal" [ngStyle]="{'display':display}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="cstmModalBody">
                <div class="orderBox" *ngIf="idleState=='Timed out!'">
                    <div mat-dialog-title style="text-align: center;"><img src="/images/order-icon1.svg"></div>
                    <h5>You have been for too long?</h5>
                    <p style="font-size: 16px;color: red;">Time Out Expired, Please Login Again!</p>
                    <div class="btnsBg">
                        <button class="btn common-btn" (click)="onCloseHandled()">Okay!</button>
                      </div>
                </div>
                <div class="orderBox" *ngIf="idleState!='Timed out!'">
                    <div mat-dialog-title style="text-align: center;"><img src="/images/order-icon1.svg"></div>
                    <h5>Due to inactivity your session has been logged out</h5>
                    
                    <p style="font-size: 14px;"><b>{{idleState}}</b></p>
                    <p> Would you like to logout?</p>
                    <div class="btnsBg">
                      <button class="btn common-btn" (click)="stay()">No, Go Back</button>
                      <button class="btn cancelBtn" (click)="logout()">Yes, Logout</button>
                    </div>
                  </div>
          </div>

      </div>
    </div>
</div>

<div class="modal cstmModal orderModal" [ngStyle]="{'display':displayQR}" id="qrModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- cstmModalBody -->
        <div class="cstmModalBody">
          <!-- cstmModalCont -->
          <div class="cstmModalCont">
            <div class="orderBox">
              <div mat-dialog-title style="text-align: right;">
                <button class="btn cancelBtn" (click)="onCloseHandledQR()">Esc</button>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#0F6957" class="bi bi-qr-code-scan" viewBox="0 0 16 16">
                <path d="M0 .5A.5.5 0 0 1 .5 0h3a.5.5 0 0 1 0 1H1v2.5a.5.5 0 0 1-1 0zm12 0a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0V1h-2.5a.5.5 0 0 1-.5-.5M.5 12a.5.5 0 0 1 .5.5V15h2.5a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5m15 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H15v-2.5a.5.5 0 0 1 .5-.5M4 4h1v1H4z"/>
                <path d="M7 2H2v5h5zM3 3h3v3H3zm2 8H4v1h1z"/>
                <path d="M7 9H2v5h5zm-4 1h3v3H3zm8-6h1v1h-1z"/>
                <path d="M9 2h5v5H9zm1 1v3h3V3zM8 8v2h1v1H8v1h2v-2h1v2h1v-1h2v-1h-3V8zm2 2H9V9h1zm4 2h-1v1h-2v1h3zm-4 2v-1H8v1z"/>
                <path d="M12 9h2V8h-2z"/>
              </svg>   
              <h5>SCAN QR CODE</h5>                 
              <p><i><b>{{clearqr}}</b></i></p>
              <input type="text" #qrcode class="form-control" tabindex="1"  [ngClass]="" placeholder="Waiting for scan" />
            </div>
          </div>
        </div>
    </div>
  </div> 
</div>


<div class="modal cstmModal orderModal" [ngStyle]="{'display': displayOrderUID}" id="orderUIDModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="cstmModalBody">
        <div class="cstmModalCont">
          <div class="orderBox">
            <div mat-dialog-title style="text-align: right;">
              <button class="btn cancelBtn" (click)="onCloseHandledOrderUID()">Esc</button>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#0F6957" class="bi bi-keyboard-fill" viewBox="0 0 16 16">
              <path d="M0 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm13 .25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25M2.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 3 8.75v-.5A.25.25 0 0 0 2.75 8zM4 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 5 8.75v-.5A.25.25 0 0 0 4.75 8h-.5a.25.25 0 0 0-.25.25M6.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 7 8.75v-.5A.25.25 0 0 0 6.75 8zM8 8.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 9 8.75v-.5A.25.25 0 0 0 8.75 8h-.5a.25.25 0 0 0-.25.25M13.25 8a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm0 2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm-3-2a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zm.75 2.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25M11.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25zM9 6.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5A.25.25 0 0 0 9.75 6h-.5a.25.25 0 0 0-.25.25M7.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 8 6.75v-.5A.25.25 0 0 0 7.75 6zM5 6.25v.5c0 .138.112.25.25.25h.5A.25.25 0 0 0 6 6.75v-.5A.25.25 0 0 0 5.75 6h-.5a.25.25 0 0 0-.25.25M2.25 6a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h1.5A.25.25 0 0 0 4 6.75v-.5A.25.25 0 0 0 3.75 6zM2 10.25v.5c0 .138.112.25.25.25h.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25h-.5a.25.25 0 0 0-.25.25M4.25 10a.25.25 0 0 0-.25.25v.5c0 .138.112.25.25.25h5.5a.25.25 0 0 0 .25-.25v-.5a.25.25 0 0 0-.25-.25z"/>
            </svg>  
            <h5>FIND 6 DIGIT ORDER UID</h5>                  
            <p><i><b>{{clearOrderUID}}</b></i></p>
            <input  #orderUIDInput class="form-control form-control-lg" type="search" placeholder="Type Order Number" minlength="6" maxlength="6" (input)="onOrderUIDInputChange($event)"/>
            <!-- <button class="btn btn-primary mt-3" type="submit" (click)="onFindOrder()">Find Order</button> -->
          </div>
        </div>
      </div>
    </div>
  </div> 
</div>



<!-- <div #quickSearchModalGlobal id="quickSearchModalGlobal" class="modal fade" role="dialog" tabindex="-1">
  <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
          <div class="modal-header bg-warning text-dark">
              <h4 class="modal-title" style="margin-bottom: 0px;">Quick Search</h4>
              <button class="btn-close text-white btn-lg" type="button" aria-label="Close" data-bs-dismiss="modal" (click)="closeModal()" style="margin-right: 15px;"></button>
          </div>
          <div class="modal-body">
              <p class="mb-4">Enter the name of the Patient</p>
              <form class="d-flex justify-content-center flex-wrap" (submit)="searchNewPatient(searchName)" #searchNameFormGlobal="ngForm" method="post" data-bs-theme="light"  >
                  <div class="input-group mb-3 w-75 shadow-lg">
                      <input [(ngModel)]="searchName" autofocus tabindex="-1" name="searchName" #searchNameInput="ngModel" class="form-control form-control-lg" type="text" placeholder="Your Patient Name" required minlength="4" maxlength="150" pattern="^[a-zA-Z,]{4,}$"/> 
                      <button [disabled]="!searchNameFormGlobal.valid" class="btn btn-warning btn-lg" type="submit">Search Name 🔎</button>
                  </div>
                  <div *ngIf="searchNameInput.invalid && (searchNameInput.dirty || searchNameInput.touched)" class="alert alert-danger" style="font-size: 0.8em; padding: 5px; margin-top: 10px;">Search Name is Mandatory (Min 4 Characters Long) and can only contain letters and numbers.</div>
              </form>
                    <div class="pt-2"></div> 
                    <ul class="list-group patient-list" *ngFor="let patient of patientsList let i = index"  (click)="setActiveRow(i, patient.id)"> 
                    <li class="list-group-item" style="font-size: 12px;" [style.background-color]="i === activeRowIndex ? 'yellow' : ''"> 📂 {{patient.id}} - {{ patient.firstName }} {{ patient.lastName }} 📅 {{patient.dob | date: 'yyyy-MM-dd' }} 🏠 ({{ patient.suburb }}, {{ patient.postcode }}, {{ patient.state }}) ➡️ {{patient.program}}</li>
                    </ul>
          </div>
          <div class="modal-footer">
              <button class="btn btn-outline-warning" type="button" data-bs-dismiss="modal" (click)="closeModal()">Close</button>
          </div>
      </div>
  </div>
</div> -->

    
