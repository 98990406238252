import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class PublicIpAddress {

  constructor(private http: HttpClient) {

  }

  public GetPublicIPAddress() {
    var ipResponse = Observable.create((observer: any) => {
      this.http.get<{ ip: string }>('https://jsonip.com')
        .subscribe(data => {
          observer.next(data.ip);
          observer.complete();
        });
    });
    return ipResponse;
  }

}
