
const ENCRYPT = {
  LOCALSTORAGEKEY: 'vio:fkey',
  SECRETKEY: '#INSERVIOFSVC*$#@BG@DASA+(*'
};

const SMARTWAYENV = {
  USERID: localStorage.getItem('userId'),
  TOKEN: localStorage.getItem('token'),
  BRANCH: Number(localStorage.getItem('branchId'))
};

export const Common = {
  ENCRYPT: ENCRYPT,
  SMARTWAY: SMARTWAYENV
};
