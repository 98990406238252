
  <div id="MenuMainBg" class="col">
        <div id="collapseMenu" class="col-12 collapse navbar-collapse" *ngIf="MenuViewModel">
          <a href="" class="iconLogo"><img src="/images/IconLogo.svg"></a>
          <ul class="col navbar-nav nav_menu" >
          <li class=""*ngFor="let item of MainMenuViewModel">
            <a [routerLink]="[item.uiPath]" *ngIf="item.parentId=='0' && item.uiPath !=''">
              <i class="{{item.iconClass}}" aria-hidden="true"></i>
                <span class="">{{item.displayName}}</span>
            </a>
            <a *ngIf="item.parentId=='0' && item.uiPath ==''" class="dropdown-toggle" role="button" id="" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="{{item.iconClass}}" aria-hidden="true"></i> <span class="">{{item.displayName}}</span></a>
            <ul class="dropdown-menu" *ngIf="item.parentId=='0' && item.uiPath ==''">
                <li *ngFor="let subitem of subMenuViewModel">
                  <a *ngIf="(subitem.parentId | uppercase) ==(item.menuId | uppercase)" [routerLink]="[subitem.uiPath]"><span class="">{{subitem.displayName}}</span></a>
              </li>
            </ul>
           </li>
          </ul>
        </div>
      </div>
