import { Injectable } from "@angular/core";
import { LoginResponseMsgModel, AccountLoginModel, AccountLoginResponseModel } from '../../common/model/authorizationmodel';
import { RequestService } from "../../services/requestservice";
import * as APIMethodConfig from '../../common/apimethod'
import { LocalStorageService } from "ngx-localstorage";
import * as CryptoJS from 'crypto-js';
import * as ConfigKey from '../common';
import { Observable, throwError } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Injectable()
export class AuthorizationHandler {
  errorMaessages: Array<string> = new Array<string>();
  error = '';
  constructor(public requestService: RequestService,
    private storageService: LocalStorageService,
    private toastr: ToastrService,
   public router: Router
  ) {
  }

  public AccountLogin(loginDetail: AccountLoginModel): Observable<LoginResponseMsgModel> {
    let loginResponse = Observable.create((observer: any) => {
      this.requestService.GetAPIPOSTService(APIMethodConfig.InservioAPIMethod.Account.Login, loginDetail).subscribe((result: AccountLoginResponseModel) => {
        if (result.status) {
          let tokenDetail = result.entity;
          let tokenDetailencoded = encodeURIComponent(CryptoJS.AES.encrypt(JSON.stringify(tokenDetail), ConfigKey.Common.ENCRYPT.SECRETKEY).toString());
          this.storageService.set(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY, tokenDetailencoded);
          this.storageService.set("pagination", 10);
          if (tokenDetail.isPasswordWarningalert == true) {
            const toast = this.toastr.warning('Your Password is will expire soon!', '', {
              timeOut: 3000,

            });
          }
          if (tokenDetail.isPasswordWarningalert == true) {
            const toast = this.toastr.warning('Your Password is will expire soon!', '', {
              timeOut: 3000,

            });
          }
          if (tokenDetail.isPasswordExpiryalert == true) {
            this.resetpassword(loginDetail.username);
          }
          
        }
        else {
          this.storageService.clear();
         
        }
        this.errorMaessages = result.returnMessage;
        if (this.errorMaessages.length > 0) {
          this.error = this.errorMaessages[0];
          if (this.error == 'Your password has expired, Please reset it !') {
            this.resetpassword(loginDetail.username);
          }
        }
        let response: LoginResponseMsgModel = {
          Status: result.status,
          ErrorMsg: result.returnMessage
        };
        observer.next(response);
        observer.complete();
      },(result : any ) => {
       
        let response: LoginResponseMsgModel = {
          Status: false,
          ErrorMsg: result.returnMessage
        };
        if(result.error.errors.UserName != undefined){
          response.ErrorMsg = result.error.errors.UserName;
        }
        else{
          response.ErrorMsg = result.error.errors.Password;
        }
        
        observer.next(response);
        observer.complete();
      });
    });
    return loginResponse;
  }
  public logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("vio:fkey");
    localStorage.removeItem("pagination");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("branchId");
  }
  resetpassword(email:string) {    
    this.router.navigate(['/updatepassword/' + email+'/'+'true']);
  }
}
