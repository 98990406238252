import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { InservioWebModules } from '../inservioweb/app/inserviowebmodules';
import { environment } from '../inservioweb/environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(InservioWebModules)
  .catch(err => console.error(err));
