
import { ErrorInterceptor } from './../../common/interceptors/error.interceptor';
import { JwtInterceptor } from './../../common/interceptors/jwt.interceptor';
import { InterceptorService } from './../../common/interceptors/HttpInterceptor';

import { AlertService } from './../../services/alertservices/alert.service';
import { AuthGuard } from './../_guards/auth.guard';
import { AuthorizationHandler } from "../../common/authorization/authorization";
import { PublicIpAddress } from "../../common/ipaddress/ipaddress";
import { RequestService } from "../../services/requestservice";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { GoogleMapAPIService } from '../../services/googlemap/service.googlemap';

export const Providers = [
  RequestService,
  AuthorizationHandler,
  PublicIpAddress,
  AuthGuard,
  AlertService,
  DatePipe,
  GoogleMapAPIService,
  InterceptorService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  },
  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
];
