import { LoginComponent } from './../../modules/login/login';
import { HistoryComponent } from './../../modules/dispensehistory/history/history.component';
import { TaskModule } from './../../modules/task/task.module';
import { AuthGuard } from './../_guards/auth.guard';
import { DashboardComponent } from '../../modules/dashboard/dashboard';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from "@angular/core";
import { TwofactorauthComponent } from '../../modules/twofactorauth/twofactorauth.component';
import { AccessDeniedComponent } from 'inservioweb/modules/accessdenied/accessdenied.component';

export const SECURE_ROUTES: Routes = [

  { path: 'dashboard', component: DashboardComponent },
  //{ path: 'login', component: LoginComponent },
  //{ path:'usermanagement', loadChildren:'inservioweb/modules/usermanagement/usermanagement.module#UsermanagementModule'},
  { path: 'usermanagement', loadChildren: () => import('inservioweb/modules/usermanagement/usermanagement.module').then(m => m.UsermanagementModule) },
  { path: 'rolemanagement', loadChildren: () => import('inservioweb/modules/rolemanagement/rolemanagement.module').then(m => m.RoleManagementModule) },
  { path: 'orders', loadChildren: () => import('inservioweb/modules/order/order.module').then(m => m.OrderModule) },
  { path: 'patients', loadChildren: () => import('inservioweb/modules/patient/patient.module').then(m => m.PatientModule) },
  { path: 'programs', loadChildren: () => import('inservioweb/modules/program/program.module').then(m => m.ProgramModule) },
  { path: 'therapy', loadChildren: () => import('inservioweb/modules/therapy/therapy.module').then(m => m.TherapyModule) },
  { path: 'tasks', loadChildren: () => import('inservioweb/modules/task/task.module').then(m => m.TaskModule) },
  { path: 'history', loadChildren: () => import('inservioweb/modules/dispensehistory/history.module').then(m => m.HistoryModule) },
  { path: 'programmaster', loadChildren: () => import('inservioweb/modules/programMaster/programmaster.module').then(m => m.ProgramMasterModule) },
  { path: 'productmanagement', loadChildren: () => import('inservioweb/modules/productMaster/productmaster.module').then(m => m.ProductMasterModule) },
  { path: 'therapymaster', loadChildren: () => import('inservioweb/modules/therapyMaster/therapymaster.module').then(m => m.TherapyMasterModule) },
  { path: 'userprofile', loadChildren: () => import('inservioweb/modules/userprofile/userprofile.module').then(m => m.UserProfileListModule) },
  { path: 'manifest', loadChildren: () => import('inservioweb/modules/manifest/manifest.module').then(m => m.ManifestModule) },
  { path: 'accessdenied', loadChildren: () => import('inservioweb/modules/accessdenied/accessdenied.module').then(m => m.AccessDeniedModule) },
  { path: 'printdocket', loadChildren: () => import('inservioweb/modules/printdocket/printdocket.module').then(m => m.PrintDocketOrderViewModule) },
  { path: 'institution', loadChildren: () => import('inservioweb/modules/institution/institution.module').then(m => m.InstitutionModule) },
  { path: 'activeorders', loadChildren: () => import('inservioweb/modules/OrderList/activeorderlist.module').then(m => m.ActiveOrderListModule) },
  { path: 'pastorders', loadChildren: () => import('inservioweb/modules/OrderList/pastorderlist.module').then(m => m.PastOrderListModule) },
  { path: '**', pathMatch: 'full', component: AccessDeniedComponent }
];

