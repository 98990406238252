import { RequestService } from './../../services/requestservice';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import * as APIMethodConfig from '../../common/apimethod';
import { ToastrService } from 'ngx-toastr';
import { UpdatePasswordViewModel } from '../../common/model/authorizationmodel';
@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.sass']
})
export class UpdatepasswordComponent implements OnInit {
  error = '';
  expiry = '';
  UserName:string='';
  pswForm:any;
  errorlog:string='';
  SubmitLoader = false;
  currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
  constructor(public router: Router,
    public route: ActivatedRoute,
    public requestService: RequestService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.UserName = params['username'];
      this.expiry = params['expiry'];
      if (this.expiry == 'true') {
        this.error ='Your password has expired, Please reset it !'
      }
    });
    if (this.UserName == null || this.UserName == '') {
      this.router.navigate(['/login']);
    }
    this.pswForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8), Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]+$')]),
      confirmpassword: new UntypedFormControl('', [Validators.required]),
      passwordcurrent: new UntypedFormControl('', [Validators.required])
    });
    this.GetInservioversionList();

  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {

        this.VersionList = response.entity;
        if (this.VersionList.length > 0) {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }
public UpdatePassword(){
  this.SubmitLoader = true;
  if(!this.pswForm.valid){
return;
  }
var formval:any=this.pswForm.value;
if(formval.password==formval.confirmpassword)
{
  let Info: UpdatePasswordViewModel = {
    userName: this.UserName,//"admininservio@yopmail.com",
    password: formval.password,//"password",
    passwordcurrent: formval.passwordcurrent
  };

  this.requestService.GetAPIPOSTService(APIMethodConfig.InservioAPIMethod.Account.UpdatePassword, Info ).
  subscribe((response: any) => {
   
    if(response.status)
    {
      this.router.navigate(['/login']);
    }
    else
    {
      this.errorlog = response.returnMessage[0];
      const toast = this.toastr.error(this.errorlog, '', {
        timeOut: 2000,

      });
    }
    this.SubmitLoader = false;
  }, (response:any) => this.onFailed(response));

 
}
else{
  this.errorlog = "Password do not match";
  
  const toast = this.toastr.error(this.errorlog, '', {
    timeOut: 2000,

  });
}
  //this.router.navigate(['/resetsuccess']);

  }
  public onFailed(response: any) {
    //throw new Error('Method not implemented.');
  }


  get f() {
    return this.pswForm.controls;
  }

}
