import { AlertService } from './../../services/alertservices/alert.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthorizationHandler } from '../../common/authorization/authorization';
import { AccountLoginModel, LoginResponseMsgModel } from '../../common/model/authorizationmodel';
import { LocalStorageService } from "ngx-localstorage";
import { IdleTimerService } from 'inservioweb/app/_services/idletimerservice';
import { RequestService } from '../../services/requestservice';
import * as APIMethodConfig from '../../common/apimethod';
import { ToastrService } from 'ngx-toastr';
declare function LoadInservioWebScript(): any;

@Component({
  selector: 'inservio-login',
  templateUrl: './login.html'
})

export class LoginComponent implements OnInit {
  SubmitLoader: boolean = false;
  error = '';
  VersionList = new Array<any>();
  version: string = '';
  currentyear: string = '';
  //submitted = false;
  errorMaessages: Array<string> = new Array<string>();
  LogInForm = new UntypedFormGroup({
    userName: new UntypedFormControl(''),
    passWord: new UntypedFormControl(''),
    rememberMe: new UntypedFormControl(false)
  });
    issuspended: boolean=false;

  constructor(public router: Router,
    public activatedRoute: ActivatedRoute,
    public authHandler: AuthorizationHandler,
    public formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private storageService: LocalStorageService,
    private idealservice: IdleTimerService,
    public requestService: RequestService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit() {
    this.storageService.clear();
    this.LogInForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false]
    });
    LoadInservioWebScript();
    this.GetInservioversionList();
  }
  get f() { return this.LogInForm.controls; }
  public Login() {
   
    
    this.SubmitLoader = true;
    if (this.LogInForm.invalid) {
      this.SubmitLoader = false;
      this.error = "User Name and Password are mandatory field's";
      return;
    }
    let username = this.f.username.value;
    let password = this.f.password.value;
    let rememberMe = this.LogInForm.get('rememberMe')?.value;

    let loginInfo: AccountLoginModel = {
      username: username,//"admininservio@yopmail.com",
      password: password,//"password",
      rememberMe: rememberMe
    };

    this.authHandler.AccountLogin(loginInfo).subscribe((loginResponse: LoginResponseMsgModel) => {
      if (loginResponse.Status) {
        this.idealservice.setUserLoggedIn(true);
        this.router.navigate(['/twofactorauth/' + loginInfo.username]);
      }
      else {
        this.loginFailed(loginResponse);
      }
    });

  }
  public loginFailed(response: any) {
    this.SubmitLoader = false;
    this.errorMaessages = response.ErrorMsg;
    if (this.errorMaessages.length > 0) {
      this.error = this.errorMaessages[0];
      const toast = this.toastr.error(this.errorMaessages[0], '', {
        timeOut: 2000,

      });
      if (this.error == 'Your account has been suspended for two hours.') {
        this.issuspended = true;
      }
    }

  }
  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {

        this.VersionList = response.entity;
        if (this.VersionList.length > 0) {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }

}
