import { TwofactorauthComponent } from './../../modules/twofactorauth/twofactorauth.component';
import { RecoverycodesComponent } from './../../modules/recoverycodes/recoverycodes.component';
import { LoginComponent } from '../../modules/login/login';
import { ResetsuccessComponent } from '../../modules/resetsuccess/resetsuccess.component';
import { UpdatepasswordComponent } from '../../modules/updatepassword/updatepassword.component';
import { OTPverificationComponent } from '../../modules/otpverification/otpverification.component';
import { ForgetpasswordComponent } from '../../modules/forgetpassword/forgetpassword.component';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from '../../common/pagenotfound/pagenotfound';
import { NgModule } from "@angular/core";
import { AccessDeniedComponent } from 'inservioweb/modules/accessdenied/accessdenied.component';

export const PUBLIC_ROUTES: Routes = [
  { path: 'login', component: LoginComponent },
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'otpverfication/:username/:expdt', component: OTPverificationComponent },
  { path: 'updatepassword/:username/:expiry', component: UpdatepasswordComponent },
  { path: 'resetsuccess', component: ResetsuccessComponent },
  { path: 'recoverycodes', component:RecoverycodesComponent},
  { path: 'twofactorauth/:username', component:TwofactorauthComponent},
  { path: '**', component: AccessDeniedComponent }
];

