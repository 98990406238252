import { LocalStorageService } from 'ngx-localstorage';
import { AuthorizationHandler } from './../../common/authorization/authorization';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
//import { AuthenticationService } from '../_services';
// import { AuthorizationHandler} from
import * as ConfigKey from '../../common/common';
import * as CryptoJS from 'crypto-js';



@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  discriminator: any;
  constructor(
    private router: Router,
    private storageService: LocalStorageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (state.url.substring(1).split('/')[0] == 'forgetpassword') {
      return true;
    }

    if (state.url.substring(1).split('/')[0] == 'updatepassword') {
      return true;
    }

    const currentUser = this.GetTokenDetail();
    if (currentUser) {
      return true;
    }

    // otherwise redirect to login page with the return url
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  public GetTokenDetail(): any {
    var deData = CryptoJS.AES.decrypt(decodeURIComponent(this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY)), ConfigKey.Common.ENCRYPT.SECRETKEY);
    var xpresso = deData.toString(CryptoJS.enc.Utf8);
    if (xpresso != null && xpresso != "") {
      return JSON.parse(xpresso);
    }
    else {
      return null;
    }
  }

  public GetPatientSortDetail(): any {
    var deData = CryptoJS.AES.decrypt(decodeURIComponent(this.storageService.get("patient-column")), ConfigKey.Common.ENCRYPT.SECRETKEY);
    var xpresso = deData.toString(CryptoJS.enc.Utf8);
    if (xpresso != null && xpresso != "") {
      return JSON.parse(xpresso);
    }
    else {
      return null;
    }
  }

  public OrderSortDetail(): any {
    var deData = CryptoJS.AES.decrypt(decodeURIComponent(this.storageService.get("order-column")), ConfigKey.Common.ENCRYPT.SECRETKEY);
    var xpresso = deData.toString(CryptoJS.enc.Utf8);
    if (xpresso != null && xpresso != "") {
      return JSON.parse(xpresso);
    }
    else {
      return null;
    }
  }

  // public get getPermissionValue(): UserPermission[] {
  //   return this.currentPermissionSubject.value;
  // }
  public isAuthenticated(discriminator: string): boolean {
    var data = this.GetTokenDetail();
    const permissions: [] = data.permissions;
    const found = permissions.some(function (element: any) {
      return element.discriminator === discriminator;
    });
    return found;
  }


  public GetRoleTitles(): string[] {
    const tokenDetail = this.GetTokenDetail();
    if (tokenDetail && tokenDetail.permissions) {
      // Extract unique role titles from permissions
      const roleTitles = tokenDetail.permissions.map((perm: any) => perm.roleTitle);
      return Array.from(new Set(roleTitles)); // Removing duplicates if any
    }
    return [];
  }

}
