import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams, HttpErrorResponse} from '@angular/common/http'
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize } from "rxjs/operators";
import { GOOGLEAPI} from '../../common/apiurl'
const GOOGLE_MAPS_API_KEY = GOOGLEAPI.KEY;
// from SEEKWELL ACCOUNT
export type Maps = typeof google.maps;

@Injectable()
export class GoogleMapAPIService {

  constructor(public http:HttpClient){
    
  }

  public readonly api = this.load();

  private load(): Promise<Maps> {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    // tslint:disable-next-line:no-bitwise
    const callbackName = `GooglePlaces_cb_` + ((Math.random() * 1e9) >>> 0);
    script.src = this.getScriptSrc(callbackName);

    interface MyWindow { [name: string]: Function; };
    const myWindow: MyWindow = window as any;

    const promise = new Promise((resolve, reject) => {
      myWindow[callbackName] = resolve;
      script.onerror = reject;
    });
    document.body.appendChild(script);
    return promise.then(() => google.maps);
  }

  private getScriptSrc(callback: string): string {
    interface QueryParams { [key: string]: string; };
    const query: QueryParams = {
      v: '3',
      callback,
      key: GOOGLE_MAPS_API_KEY,
      libraries: 'places'
    };
    const params = Object.keys(query).map(key => `${key}=${query[key]}`).join('&');
    return `//maps.googleapis.com/maps/api/js?${params}&language=en&region=au`;
  }


  validateAddress<ValidateAddressResult>(address:string){
    return this.http.post<ValidateAddressResult>("https://addressvalidation.googleapis.com/v1:validateAddress?key="+GOOGLE_MAPS_API_KEY,
     {
       "address": {
         "regionCode": "Au",
         "addressLines": [address]
       }
     },
     {
       headers: new HttpHeaders({
         'Content-Type':  'application/json'
       })
     }
   ).toPromise();
   
 }
 

}
interface Verdict {
  inputGranularity: string;
  validationGranularity: string;
  geocodeGranularity: string;
  addressComplete: boolean;
  hasUnconfirmedComponents: boolean;
  hasInferredComponents: boolean;
}

 interface PostalAddress {
  regionCode: string;
  languageCode: string;
  postalCode: string;
  administrativeArea: string;
  locality: string;
  addressLines: string[];
}

 interface ComponentName {
  text: string;
  languageCode: string;
}

 interface AddressComponent {
  componentName: ComponentName;
  componentType: string;
  confirmationLevel: string;
  inferred?: boolean;
}

 interface Address {
  formattedAddress: string;
  postalAddress: PostalAddress;
  addressComponents: AddressComponent[];
  unconfirmedComponentTypes: string[];
}

 interface Location {
  latitude: number;
  longitude: number;
}

 interface PlusCode {
  globalCode: string;
}

 interface Low {
  latitude: number;
  longitude: number;
}

 interface High {
  latitude: number;
  longitude: number;
}

 interface Bounds {
  low: Low;
  high: High;
}

 interface Geocode {
  location: Location;
  plusCode: PlusCode;
  bounds: Bounds;
  placeId: string;
  placeTypes: string[];
}

 interface Metadata {
  business: boolean;
  residential: boolean;
}

 interface Result {
  verdict: Verdict;
  address: Address;
  geocode: Geocode;
  metadata: Metadata;
}

 export interface ValidateAddressResult {
  result: Result;
  responseId: string;
}