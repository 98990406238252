import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  template: `
    <div class="NotFoundDiv">
      <!-- page-not-found works! -->
      <div class="col">
        <img src="/images/no-result.svg">
        <p>Page not found</p>
      </div>
    </div>
  `,
  styles: []
})
export class PageNotFoundComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
