 <!-- wrapper -->
 <div class="wrapper col">
    <div class="row LoginBg">

      <div class="col lftImg"></div>
      <div class="col signTextBg">
        <div class="d-flex">
          <a href=""><img src="/images/logo1.svg"></a>
          <div class="signText">
            <div class="textBox">
              Our mission is to 
make lives easier for 
patients and doctors
            </div>
          </div>
          <div class="copyright-inner m-0">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. {{version}} </div>
        </div>
      </div>

      <!-- logIn-Rght -->
      <div class="col logIn-Rght">
        <div class="loginCont OTP-cont">
          <h1>OTP Verification</h1>
          <p>We have sent OTP on your email  </p>
          <p>' <b>{{unmeshow}}</b> '</p>
          <div class="loginForm m-0">
            <div class="otpBg">
              <input type="text" class="form-control" maxlength="1" autofocus>
              <input type="text" class="form-control" maxlength="1">
              <input type="text" class="form-control" maxlength="1">
              <input type="text" class="form-control" maxlength="1">
              <input type="text" class="form-control" maxlength="1">
              <input type="text" class="form-control" maxlength="1">
            </div>
            <button type="button"  (click)="VerfyOTP()" class="common-btn">Verify Now</button>
            <br/>
            <!-- <div class="timer" *ngIf="timerOn" >
             <span style="font-family: 'digital-clock-font'; color: #0D6856;margin-left: 50px;font-size: 18px;font-weight: bold;">Time Remaining ..
              <span id="minutes"> {{minutesToDday}} </span>Min
              <span id="seconds"> {{secondsToDday}} </span>S</span>
          </div> -->
            <div class="resend">
              <a (click)="ResendOTP()">Resend code</a>
            </div>
           
         
            <div *ngIf="successmessage" class="alert alert-success">{{successmessage}}</div>
          </div>
        </div>
      </div>
      <!-- logIn-Rght -->

    </div>
  </div>
  <!-- wrapper -->