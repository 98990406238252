import { AuthGuard } from '../app/_guards/auth.guard';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import * as APIConfig from '../common/apiurl';
import { catchError, finalize } from "rxjs/operators";
@Injectable()
export class RequestService {

  constructor(public http: HttpClient,
    private AuthGuard: AuthGuard) {

  }

  public GetAPIPOSTService(apiMethod: string = '', body: object = {}): Observable<any> {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;

    return this.http.post<Observable<any>>(URL,
      JSON.stringify(body),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; charset=utf-8'
        }),
      });
  }


  HttpGet<T>(apiMethod: string = '', param: any): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();

    //const securityToken = localStorage.getItem("token");//?localStorage.getItem("token"):''
    if (securityToken != null) {
      let tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json")
        .set("Access-Control-Allow-Origin", "*");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http.get<T>(URL, { params: param, headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
          //this.alertService.stopProgressBar();
        })
      );
  }


  HttpGetNoParam<T>(apiMethod: string): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http
      .get<T>(URL, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
          //code to stop loader or something as you wish
        })
      );
  }

  HttpGetValidate<T>(apiMethod: string, title: string, value: string): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http
      .get<T>(URL + "/" + title + "/" + value, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );

  }

  HttpGetMed<T>(apiMethod: string = '', param: any): any {

    let URL = APIConfig.InservioAPI.InservioURL.MedAPI + apiMethod;
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorizationToken': 'inservio#api#00112233445566778899'
    });

    return this.http.get<T>(URL, { params: param, headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
          //this.alertService.stopProgressBar();
        })
      );
  }

  HttpGetLocation<T>(apiMethod: string = '', param: any): any {
    let URL = '';
    //if (localStorage.getItem('issandboxurl') == 'true') {
    //  URL = APIConfig.InservioAPI.InservioURL.SANBOXAPI + apiMethod;
    //}
    //else {
    //   URL = APIConfig.InservioAPI.InservioURL.SMARTAPI + apiMethod;
    //}
    URL = localStorage.getItem('smartwaysapi') + apiMethod;
    let httpHeaders = new HttpHeaders({
      //  'Content-Type': 'application/json'			  

    });

    return this.http.get<T>(URL, { params: param, headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
          //this.alertService.stopProgressBar();
        })
      );
    // .subscribe(data => {
    // 	var xx = data;
    // })        
  }
  HttpBlobLocation<T>(apiMethod: string = '', param: any): any {
    let URL = '';
    URL = localStorage.getItem('smartwaysapi') + apiMethod;
    //let URL = APIConfig.InservioAPI.InservioURL.SMARTAPI + apiMethod;
    let httpHeaders = new HttpHeaders({
      //  'Content-Type': 'application/json'			  

    });
    return this.http
      .get<T>(URL, { params: param, headers: httpHeaders, responseType: 'blob' as 'json' })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }

  GetHttpBlobFile<T>(apiMethod: string = '', param: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    let tokenString = "";
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http
      .get<T>(URL, { params: param, headers: httpHeaders, responseType: 'blob' as 'json' })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }


  HttpGetScript<T>(apiMethod: string = ''): any {

    let URL = apiMethod;
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'authorizationToken': 'inservio#api#00112233445566778899'
    });

    return this.http.get<T>(URL, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {
          //this.alertService.stopProgressBar();
        })
      );
  }

  HttpPOSTPassword<T>(apiMethod: string, userName: string): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http.post<T>(URL + "/" + userName, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );

  }
  HttpPostNParam<T>(apiMethod: string): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http.post<T>(URL, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );

  }




  HttpGetById<T>(apiMethod: string, id: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http
      .get<T>(URL + "/" + id, { headers: httpHeaders })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }
  HttpPost<T>(apiMethod: string, data: any): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;

    // const securityToken: string = this.sessionService.userViewModel.token;
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http.post<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {

      })
    );
  }


  HttpPostById<T>(apiMethod: string, id: any, data: any): any {
    // Construct the URL with the ID parameter
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod + "/" + id;

    // Set up the authorization header if token is available
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    // Execute POST request with the URL containing ID and the data payload
    return this.http.post<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {
        // Optional: any cleanup or finalization logic
      })
    );
}


  HttpPostWithFile<T>(apiMethod: string, data: FormData): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
  
    // Retrieve the token for authorization
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
  
    // Check if a token is available, and set the authorization header
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString);
      // Note: No Content-Type header is set here because FormData handles it automatically
    }
  
    // Make the HTTP POST request with the FormData object
    return this.http.post<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {
        // Finalize actions, if any
      })
    );
  }
  

  HttpPostWithResponseType<T>(apiMethod: string, data: any, responseType: 'json' | 'text' = 'json'): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;

    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
        tokenString = `Bearer ${securityToken.token}`;
        httpHeaders = new HttpHeaders()
            .set("authorization", tokenString)
            .set("Content-Type", "application/json");
    } else {
        httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    const options = {
        headers: httpHeaders,
        responseType: responseType as 'json' // This line is crucial for handling response types
    };

    return this.http.post<T>(URL, data, options).pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {})
    );
}


  HttpPatch<T>(apiMethod: string, data: any): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;

    // const securityToken: string = this.sessionService.userViewModel.token;
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http.patch<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {

      })
    );
  }

  HttpPostFile<T>(apiMethod: string, data: any): any {

    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;

    // const securityToken: string = this.sessionService.userViewModel.token;
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString);

      // .set('Content-Length',data.length);
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http.post<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {

      })
    );
  }
  HttpPut<T>(apiMethod: string, data: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    const securityToken = this.AuthGuard.GetTokenDetail();
    let httpHeaders = new HttpHeaders();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http.put<T>(URL, data, { headers: httpHeaders }).pipe(
      catchError(err => this.handleError(err)),
      finalize(() => {

      })
    );
  }
  HttpDelete<T>(apiMethod: string, data: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http
      .delete<T>(URL + "/" + data, {
        headers: httpHeaders
      })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }

  HttpDeleteQueryParam<T>(apiMethod: string): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    return this.http
      .delete<T>(URL, {
        headers: httpHeaders
      })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }

  HttpDeleteWithResponseType<T>(apiMethod: string, responseType: 'json' | 'text' = 'json'): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
        tokenString = `Bearer ${securityToken.token}`;
        httpHeaders = new HttpHeaders()
            .set("authorization", tokenString)
            .set("Content-Type", "application/json");
    } else {
        httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }

    const options = {
        headers: httpHeaders,
        responseType: responseType as 'json' // This line is crucial for handling response types
    };

    return this.http
        .delete<T>(URL, options)
        .pipe(
            catchError(err => this.handleError(err)),
            finalize(() => {})
        );
}


  HttpBlobGetById<T>(apiMethod: string, id: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http
      .get<T>(URL + "/" + id, { headers: httpHeaders, responseType: 'blob' as 'json' })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }

  HttpBlobGetById2<T>(apiMethod: string, id: any): any {
    let URL = APIConfig.InservioAPI.InservioURL.API + apiMethod;
    let tokenString = "";
    let httpHeaders = new HttpHeaders();
    const securityToken = this.AuthGuard.GetTokenDetail();
    if (securityToken != null) {
      tokenString = `Bearer ${securityToken.token}`;
      httpHeaders = new HttpHeaders()
        .set("authorization", tokenString)
        .set("Content-Type", "application/json");
    } else {
      httpHeaders = new HttpHeaders().set("Content-Type", "application/json");
    }
    return this.http
      .get<T>(URL + id, { headers: httpHeaders, responseType: 'blob' as 'json' })
      .pipe(
        catchError(err => this.handleError(err)),
        finalize(() => {

        })
      );
  }


  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof Error) {
      //// A client-side or network error occurred. Handle it accordingly.
      //	this.alertService.ShowErrorMessage("An error occurred: " + error.error.message);
    } else {
      // // The backend returned an unsuccessful response code.
      // // The response body may contain clues as to what went wrong.
      // // console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);

      // // this.alertService.ShowErrorMessage(`Backend returned, body was: ${error.error}`);
      // // const body = error.error;
      // // const errorStatusText = error.statusText;
      // // const errorMessage = error.message;
      // // console.log(errorStatusText + ' *** ' + errorMessage + '///');
    }
    // Return an observable with a user-facing error message.
    return throwError(error);
  }


}

export class HeaderService {
  headerdata = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=utf-8'
    }),
  }
}


